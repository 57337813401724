body {
    font-family: 'Poppins', sans-serif !important;
    line-height: 1.5 !important;
}

.contactusbutton {
     
  
    background-color: #EC3B4F;
    color: #fff;
    margin-top: 5%;
    padding: 5%;
    font-size: 20px;
    font-weight: 700;
    border-radius: 10px;
    appearance: none;
    text-decoration: none;
    color: #fff;
    border: none;
}
.contactusbutton:hover{
    background-color: #F45947;
    color:white;
}
.contactusbutton:active{
    background-color: white;
    transform: translateX(5px)
}

* {
    margin: 0;
    padding: 0;
}

a:hover {
    transform: scale(1.045) !important;
    color: #fff;
}

.partners-ul > li {
    transition:all 1s ease-in;
}

.partners-ul > li :hover{
    transform: scale(1.05) !important;
}

body, html {
    overflow-x: hidden !important;
}

/* BUtton css */

.rec-pagination {
    display: none !important;
}

.rec-arrow-left {
    box-sizing: border-box;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-size: 1em !important;
    background-color: transparent !important;
    color: black !important;
    box-shadow: 0 0 0px 0px #333 !important;
    border-radius: 0 !important;
    border: none !important;
    padding: 0 !important;
    width: 50px !important;
    height: 50px !important;
    min-width: 50px !important;
    line-height: 50px !important;
    -webkit-align-self: center !important;
    -ms-flex-item-align: center !important;
    align-self: center !important;
    cursor: pointer !important;
    outline: none !important;
    appearance: none !important;
    -webkit-appearance: none !important;
}

.rec-arrow-right {
    box-sizing: border-box;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-size: 1em !important;
    background-color: transparent !important;
    color: black !important;
    box-shadow: 0 0 0px 0px #333 !important;
    border-radius: 0 !important;
    border: 0px solid #415a6c !important;
    padding: 0 !important;
    width: 50px !important;
    height: 50px !important;
    min-width: 50px !important;
    line-height: 50px !important;
    -webkit-align-self: center !important;
    -ms-flex-item-align: center !important;
    align-self: center !important;
    cursor: pointer !important;
    outline: none !important;
    appearance: none !important;
    -webkit-appearance: none !important;
}

.rec-arrow-left:hover {
    color: #ec3750 !important;
    transform: scale(1.5);
    transform: translate(-30%, 0%);
}

.rec-arrow-right:hover {
    color: #ec3750 !important;
    transform: scale(1.5);
    transform: translate(30%, 0%);
}

.navbar-event {
    margin-top: 190px;
}

.navbar-events-div {
    margin: 40px 25px;
    margin-top: -55px;
    padding: 25px;
    border: 1px solid rgb(170, 169, 169);
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.homecontainer {
    background: black;
    margin-top: -340px;
    width: 100%;
    height: 700px
}

.homediv {
   
    margin-top: 250px;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;
    height: 1000px
}

/* Media queries to handle the background gif */

@media screen and (min-width: 1433px) and (max-width: 1570px) {
    .homediv {
        margin-top: 240px;
    }
}

@media screen and (min-width: 1180px) and (max-width: 1432px) {
    .homediv {
        margin-top: 310px;
    }
}

@media screen and (min-width: 864px) and (max-width: 1179px) {
    .homediv {
        margin-top: 365px;
    }
}

@media screen and (min-width: 700px) and (max-width: 863px) {
    .homediv {
        margin-top: 400px;
    }
}

@media screen and (max-width: 699px) {
    .homediv {
        margin-top: 470px;
    }
}

/* navbar-class */

.navbar-class {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 130px;
    padding: 0.75rem 0;
    color: rgb(190, 86, 103);
    z-index: 1;
    background: black;
}

.nav-header-class {
    display: flex;
    justify-content: space-between;
}

.nav-btn-class {
    background: #313130;
    width: 100px;
    height: 50px;
    border: none;
    cursor: pointer;
    outline: none;
    margin-top: 10px;
    margin-right: 0;
}

.logo {
    width: 60px;
    height: 60px;
    margin-top: 10px;
    margin-left: 30px;
}

.nav-icon-class {
    font-size: 1.5rem;
    color: rgb(190, 86, 103);
}

.nav-links-class {
    height: 0;
    overflow: hidden;
    transition: all 0.3s linear;
    list-style-type: none;
}

.nav-links-class a {
    display: block;
    text-decoration: none;
    color: rgb(190, 86, 103);
    padding: 1rem 0;
    transition: all 0.3s linear;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 3px;
}

.nav-links-class a:hover {
    color: rgb(167, 92, 105);
}

.show-nav-class {
    height: 170px;
}

@media screen and (min-width: 768px) {
    .nav-btn-class {
        display: none;
    }
    .nav-center-class {
        max-width: 1170px;
        margin: 0 auto;
        display: flex;
        align-items: center;
    }
    .nav-links-class {
        height: auto;
        display: flex;
        margin-left: 4rem;
    }
    .nav-links-class a {
        color: rgb(190, 86, 103);
        margin: 0 1rem;
        padding: 0.5rem 0;
        font-size: 25px;
    }
}

@media screen and (max-width: 768px) {
    .nav-links-class a {
        margin-left: 0px;
        margin-top: 10px;
        top: 0px;
        padding: 0;
        font-size: 20px;
    }
}

/* end of navbar-class */

/* Home Main Area  */

.homemaindiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.homemainheading {
    color: #f3f3f3;
    font-size: 40px;
    position: absolute;
    top: 65%;
}

.homemaintagline {
    color: #f3f3f3;
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 75%;
}



.homelearnmorebutton {
    position: absolute;
    top: 90%;
    background-color: #ec3750;
    color: #fff;
    padding: 16px;
    font-size: 20px;
    font-weight: 700;
    border-radius: 10px;
    appearance: none;
    text-decoration: none;
    color: #fff;
    border: none;
}

.homelearnmorebutton:hover {
    transform: scale(1.0625);
    box-shadow: 0 1px 2px rgb(0 0 0 / 6%), 0 8px 12px rgb(0 0 0 / 13%);
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .homemainheading {
       
        color: #f3f3f3;
        font-size: 40px;
        position: absolute;
        top: 55%;
        width: 70%;
        margin: 0 auto;
        text-align: center;
    }
    .homemaintagline {
       
        color: #f3f3f3;
        font-size: 18px;
        position: absolute;
        top: 75%;
        width: 70%;
        margin: 0 auto;
        text-align: center;
    }
    .homelearnmorebutton {
        font-size: 13px;
        text-align: center;
    }
}

/* Home Main Area END */

/*event*/

.eventcontainer {
    height: 200px;
    margin-top: 40px;
}

.eventheading {
    
    text-align: center;
    font-size: 40px;
   
}

.event-number {
    margin-bottom: 20px;
    margin-left: 30px;
    font-size: 30px;
    color: rgb(175, 57, 57);
}

.eventcontainer div {
    width: 24rem;
    height: 5px;
    /* background: #BC544B; */
    color: black;
    background-color: black;
    margin: 1rem auto;
}

.event-center {
    width: 80vw;
    margin-top: 10px;
    margin-left: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    grid-row-gap: 2rem;
    grid-column-gap: 40px;
}

@media screen and (min-width: 776px) and (max-width: 991px) {
    .event-center {
        width: 90vw;
        grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    }
}

@media screen and (min-width: 992px)and (max-width: 1349px) {
    .textcasediv h2 {
        font-weight: bolder !important;
        position:absolute;
        top:52vh !important ;
        left:2vw !important;
        margin-left: 0px !important;
        color:#ec3750
        
    }

    .taglinediv{
        position:absolute;
        top:58vh !important;
        left:5vw !important;
    
    }

    .taglinediv h5 {
        margin-left : 4% !important;
        text-align: left !important;
    }

    .taglinediv a {
        margin-left : 2% !important;
       
        
    }



    .event-center {
        width: 95vw;
        max-width: 1170px;
    }
}

@media screen and (min-width: 1350px) {
    .event-center {
        width: 80vw;
        margin: auto;
        max-width: 1170px;
        margin-left: -150px;
    }
}

.event-image {
    width: 320px;
    height: 320px;
    border-radius: 10px;
    box-shadow: 0px 0px 7px 7px rgb(70, 65, 65);
}

.event-center h2 {
    color: #FF2800;
    margin-left: -130px;
    transition-delay: 3ms;
    font-size: 40px;
}

.event-center h3 {
    color: #1f2d3d;
    transition-delay: 3ms;
    margin-left: -20px;
    font-size: 30px;
    margin-top: 20px;
}

.event-center p {
    color: #1f2d3d;
    transition-delay: 3ms;
    margin-left: -130px;
    font-size: 20px;
    margin-top: 20px;
}

.event-center a input {
    margin-right: 78px;
    float: right;
    margin-top: 20px;
    background-color: #ec3750;
    color: #fff;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 0px;
    border: none;
    -webkit-appearance: none;
    appearance: none;
    text-decoration: none;
}



.homecontainer {
    height: 100%;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .home {
        height: 100vh !important;
    }
    .homecontainer {
        /* min-width: 130vw ; */
        margin-top: -350px !important;
        height: auto !important;
    }
    .navbar-brand {
        margin-left: -50%;
    }

    .textcasediv{
        width:50% !important;
    }
    .textcasediv h2 {
        font-weight: bolder !important;
        color:#ec3750 !important;
        position: absolute;
        top: 21% !important;
        left: 6vw !important;
        font-size:0.95em !important;
    }
    .taglinediv {
        position: absolute;
        /* top: 18.94% !important; */
        top: 21% !important;
        left: 6.4vw !important;
        height: 8.2em !important;
        padding: 0 !important;
    }
    .taglinediv h5 {
        font-size: 0.6em !important;
        text-align: left !important;
    }
    .taglinediv a {
        height: 3.5vh !important;
        font-size:7px !important;
        padding: 1.2px 0px !important;
        margin-bottom: 15px auto !important;
        margin-left:1% !important;
        border-radius: 4px !important;
        left:1.5vw !important;
    }
    .showcasediv {
        height: 40vh !important;
        margin-bottom: 0px !important;
    }

    .showcasingcolumndiv {
        background-size: 95% !important;
        /* margin-top : 250px !important; */
        height: 50%;
        margin:0 !important ;
     top:15% !important;
     width:50% !important;
     margin-bottom: 100px;
      
    
    }
    .eventscontent-column {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    /* Events  */
    .eventcontainer-class {
        height: 1000px !important;
    }
    
    .cardscontainer {
        height: 400px !important;
    }
    .eventscontent-column h2 {
        font-size:1.25em !important;
        justify-content: center;
        width: 100%;
        padding-bottom: 5px !important;
    }
    .eventscontent-paragraph {
        font-size: 16px !important;
        padding-top: 6px !important;
        padding-bottom: 15px !important;
    }
    .events-card-home {
        width: 100% !important;
        height: 500px !important;
    }
    .events-card-home .event-image-class {
        height: 40% !important;
        width: 100% !important;
    }
    .card-date {
        font-size: 3.5vw !important;
    }
    .events-card-home .float-right {
        font-size: 3.6vw !important;
    }
    .events-card-home .card-title {
        font-size: 18px !important;
        width: 100%;
    }
    .events-card-home .card-text {
        font-size: 12px !important;
    }
    .ourteamheading {
        font-size: 5vw !important;
    }
    .email {
        font-size: 2.9vw !important;
    }
    .team-card {
        margin-bottom: 10vh !important;
    }
    .our-team .pic {
        height: auto !important;
    }
   
    .site-footer {
        text-align: center;
    }
    .site-footer p {
        width: 100% !important;
    }
    .homemaindiv {
        padding: 10vw;
    }
    .homemainheading {
        font-size: 6vw;
        margin: 0 auto !important;
        white-space: nowrap;
    }
    .homemaintagline {
        margin: 0 25px !important;
        margin-top: -75px !important;
        font-size: 5vw;
        text-align: center;
        width: 100%;
    }
    .homelearnmorebutton {
        margin-top: -50px;
        font-size: 3.5vw;
    }
    /* Css for home page event  */
   
    .eventcontainer {
        margin-bottom: 0px !important;
    }
    .event-image {
        width: 100%;
    }
    .event-center {
        width: 100%;
        margin-top: 10px;
        display: block !important;
    }
    .event-center a {
        width: 100%;
        text-align: center;
        display: flex;
        margin-right: 0 !important;
        justify-content: center;
    }
    .event-center a input {
        text-align: center;
        display: flex;
        margin-top: 25px;
        margin-right: 0 !important;
        justify-content: center;
        border-radius: 12px;
    }
    .event-image {
        width: 100%;
        margin: 0 !important;
    }
    .eventheading {
        font-size: 40px;
        text-align: center;
        display: flex;
        width: 100%;
        justify-content: center;
    }
    
 
    .event-center h2 {
        text-align: center;
        margin: 10px 0px !important;
        width: 100%;
        font-size: 5vw;
    }
    .event-center h3 {
        color: rgb(33, 33, 95);
        text-align: center;
        margin: 10px 0px !important;
        width: 100%;
        font-size: 4.5vw;
    }
    .event-center p {
        font-size: 4vw;
    }
}

/* Small Mobile size ended  */

/* 481px — 768px */

@media screen and (min-width: 320px) and (max-width: 380px) {
    .teams-special-margin {
        margin-top: 26em !important;
    }
}

@media screen and (min-width: 381px) and (max-width: 430px) {
    .teams-special-margin {
        margin-top: 30.5em !important;
    }
}

@media screen and (min-width: 431px) and (max-width: 480px) {
    .teams-special-margin {
        margin-top: 26.5em !important;
    }
}

@media screen and (min-width: 481px) and (max-width: 650px) {
    .site-footer {
        text-align: center;
    }
    .site-footer p {
        width: 100% !important;
    }
    .home {
        height: 100vh !important;
    }

    /* Css for home page event  */
    .homecontainer {
        /* min-width: 130vw ; */
        margin-top: -350px !important;
        height: auto !important;
    }
    .navbar-brand {
        margin-left: -50%;
    }
    .taglinediv {
        position: absolute;
        top: 21.94% !important;
        height: 12.5em !important;
        left: 6.4vw !important;
        padding: 0 !important;
    }
    .taglinediv h5 {
        font-size: 15px !important;
    }
    .taglinediv a {
        height: 5.5vh !important;
        font-size: 2.4vw !important;
        padding: 5px 0px !important;
        margin-bottom: 15px auto !important;
    }
    .showcasediv {
        height: 65vh !important;
        margin-bottom: 0 !important;
    }
    .eventscontent-column {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    /* Events  */
    .eventcontainer-class {
        height: 1000px !important;
    }
 
    .cardscontainer {
        height: 400px !important;
    }
    .eventscontent-column h2 {
       
        font-size: 1.75em;
        justify-content: center;
        width: 100%;
        padding-bottom: 5px !important;
    }
    .eventscontent-paragraph {
        font-size: 24px !important;
        padding-top: 6px !important;
        padding-bottom: 15px !important;
    }
    .events-card-home {
        width: 100% !important;
        height: 500px !important;
    }
    .events-card-home .event-image-class {
        height: 40% !important;
        width: 100% !important;
    }
    .card-date {
        align-items: center !important;
        font-size: 3vw !important;
    }
    .events-card-home .float-right {
        padding-bottom: 5px !important;
        align-items: center !important;
        font-size: 3vw !important;
    }
    .events-card-home .card-title {
        line-height: 5%;
        /* margin-top:20px !important; */
        padding-top: 20px !important;
        padding-bottom: 10px !important;
        font-size: 3.5vw !important;
        width: 100%;
    }
    .events-card-home .card-text {
        text-align: justify !important;
        font-size: 2.55vw !important;
    }
    .ourteamheading {
        font-size: 5vw !important;
    }
    .email {
        font-size: 1.45vw !important;
        text-align: center;
        display: none !important;
    }
    .team-card {
        margin-bottom: 10vh !important;
    }
    .our-team .pic {
        height: auto !important;
    }
    /* Css for home page event  */
    .Event {
        height: 100% !important;
        /* width: 120vw; */
    }
    .eventcontainer {
        margin-bottom: 0px !important;
    }
    .event-image {
        width: 100%;
    }
    .event-center {
        width: 100%;
        margin-top: 10px;
        display: block !important;
    }
    .event-center a {
        width: 100%;
        text-align: center;
        display: flex;
        margin-right: 0 !important;
        justify-content: center;
    }
    .event-center a input {
        text-align: center;
        display: flex;
        margin-top: 25px;
        margin-right: 0 !important;
        justify-content: center;
        border-radius: 12px;
    }
    .event-image {
        width: 100%;
        margin: 0 !important;
    }
    .eventheading {
        font-size: 40px;
        text-align: center;
        display: flex;
        width: 100%;
        justify-content: center;
    }
   
    .event-center h2 {
        text-align: center;
        margin: 10px 0px !important;
        width: 100%;
        /* white-space: nowrap; */
        font-size: 4.5vw;
    }
    .event-center h3 {
        color: #1f2d3d;
        text-align: center;
        margin: 10px 0px !important;
        width: 100%;
        font-size: 4vw;
    }
    .event-center p {
        font-size: 4vw;
        text-align: justify;
    }
    .teams-special-margin {
        margin-top: 43.95em !important;
    }
}

@media screen and (min-width: 615px) and (max-width: 650px) {
    .teams-special-margin {
        margin-top: 46.95em!important;
    }
}

@media screen and (min-width: 481px) and (max-width: 550px) {
 
    .textcasediv h2 {
        position: absolute;
        top: 14% !important;
        left: 8vw !important;
        font-size: 64px !important;
    }
    .taglinediv {
        position: absolute;
        top: 20.94% !important;
        left: 6vw !important;
        height: 11.95em !important;
        padding: 0 !important;
    }
    /* .taglinediv h5 {
        font-size: 48px !important;
    } */
    .taglinediv a {
        height: 6.5vh !important;
        font-size: 2.6vw !important;
        padding: 12px 0px !important;
        margin-bottom: 15px auto !important;
    }
    .showcasediv {
        height: 60vh !important;
        margin-bottom: 0 !important;
    }
    .teams-special-margin {
        margin-top: 42.95em !important;
    }
}

@media screen and (min-width: 651px) and (max-width: 768px) {
    .site-footer {
        text-align: center;
    }
    .site-footer p {
        width: 100% !important;
    }
    /* Css after adding team and event new  */
    .home {
        height: 100vh !important;
    }

    /* Css for home page event  */
    .homecontainer {
        
        margin-top: -350px !important;
        height: auto !important;
    }
    .navbar-brand {
        margin-left: -50%;
    }
   
    .taglinediv {
        position: absolute;
        top: 58vh !important;
        height: 14.5em !important;
        padding: 0 !important;
        left: 6.1vw !important;
    }
    .taglinediv h5 {
        font-size: 19px !important;
    }
    .taglinediv a {
        height: 5.5vh !important;
        font-size: 2.4vw !important;
        padding: 5px 0px !important;
        margin-bottom: 15px auto !important;
    }
    .showcasediv {
        height: 74vh !important;
        margin-bottom: 0 !important;
    }
    .eventscontent-column {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-top: 15px !important;
        padding-bottom: 0 !important;
    }
    /* Events  */
    .eventcontainer-class {
        height: 1000px !important;
    }
   
    .cardscontainer {
        height: 400px !important;
    }
    .eventscontent-column h2 {
      
        font-weight: 500 !important;
        font-size: 2em !important;
        justify-content: center;
        width: 100%;
        padding-bottom: 5px !important;
    }
    .eventscontent-paragraph {
        font-size: 24px !important;
        padding-top: 6px !important;
        padding-bottom: 15px !important;
    }
    .events-card-home {
        width: 50% !important;
        margin-top: 25px !important;
        width: 100% !important;
        height: 500px !important;
    }
    .events-card-home .event-image-class {
        height: 40% !important;
        width: 100% !important;
    }
    .card-date {
        align-items: center !important;
        font-size: 2vw !important;
    }
    .events-card-home .float-right {
        padding-bottom: 5px !important;
        align-items: center !important;
        font-size: 2vw !important;
    }
    .event-image-class {
        width: 50% !important;
    }
    .events-card-home .card-title {
        line-height: 5%;
        /* margin-top:20px !important; */
        padding-top: 20px !important;
        padding-bottom: 10px !important;
        font-size: 2.75vw !important;
        width: 100%;
    }
    .events-card-home .card-text {
        text-align: justify !important;
        font-size: 2vw !important;
    }
    .ourteamheading {
        font-size: 5vw !important;
    }
    .email {
        font-size: 1.45vw !important;
        text-align: center;
        display: none !important;
    }
    .team-card {
        margin-bottom: 10vh !important;
    }
    .our-team .pic {
        height: auto !important;
    }
    /* Css for home page event  */
    .Event {
        height: 100% !important;
        /* width: 120vw; */
    }
    /* Css for home page event  */
    .homecontainer {
     
        position: relative;
        max-height: 100vh;
    }
   
    .homediv {
        margin-top: 470px;
    }
    .homemainheading {
        top: 44% !important;
        font-size: 4vw;
        margin: 0 auto !important;
        white-space: nowrap;
    }
    .homemaintagline {
        top: 62% !important;
        margin: 0px 25px !important;
        margin-top: -60px !important;
        font-size: 3.5vw;
        text-align: center;
        width: 100%;
    }
    .homelearnmorebutton {
        top: 78% !important;
        margin-top: -20px;
        font-size: 2.5vw;
    }
    /* Css for home page event  */
    .Event {
        height: 100% !important;
        /* width: 120vw; */
    }
    .eventcontainer {
        margin-bottom: 0px !important;
    }
    .event-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50vw !important;
        text-align: center;
        margin: 0 auto !important;
        height: 50vh;
    }
    .event-center {
        width: 100%;
        margin-top: 10px;
        display: block !important;
    }
    .event-center a {
        width: 100%;
        text-align: center;
        display: flex;
        margin-right: 0 !important;
        justify-content: center;
    }
    .event-center a input {
        text-align: center;
        display: flex;
        margin-top: 25px;
        margin-right: 0 !important;
        justify-content: center;
        border-radius: 12px;
    }
    .event-image {
        width: 100%;
        margin-bottom: 25px !important;
    }
    .eventheading {
        font-size: 40px;
        text-align: center;
        display: flex;
        width: 100%;
        justify-content: center;
    }
   
    .event-center h2 {
        text-align: center;
        margin: 10px 0px !important;
        width: 100%;
        white-space: nowrap;
        font-size: 3.5vw;
    }
    .event-center h3 {
        color: #1f2d3d;
        text-align: center;
        margin: 10px 0px !important;
        width: 100%;
        font-size: 3vw;
    }
    .event-center p {
        margin: 0 auto !important;
        text-align: center;
        font-size: 2.8vw;
    }
    .teams-special-margin {
        margin-top: 53em !important;
    }
}

@media screen and (min-width: 651px) and (max-width: 700px) {
    .teams-special-margin {
        margin-top: 48em !important;
    }
    .showcasediv {
        height: 69vh !important;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    /* Css for home page event  */
    .homecontainer {
        /* min-width: 130vw ; */
        position: relative;
        max-height: 100vh;
    }
    .homediv {
        margin-top: 470px;
    }
    /* NEw css for team and event section */
    .home {
        height: 100vh !important;
    }
    /* Css for home page event  */
    .homecontainer {
        /* min-width: 130vw ; */
        margin-top: -350px !important;
        height: auto !important;
    }
    .navbar-brand {
        margin-left: -50%;
    }
    .textcasediv h2 {
        position: absolute;
        top: 80vh !important;
        left: 6vw !important;
        font-size: 2em !important;
    }
    .taglinediv {
        position: absolute;
        top: 80vh !important;
        left: 4.5vw !important;
        height: 17.5em !important;
        padding: 0 !important;
    }
    .taglinediv h5 {
        font-size: 1em !important;
        text-align: left !important;
        margin-bottom:0 !important;
    }
    .taglinediv a {

        height: 7.5vh !important;
        font-size: 2vw !important;
        padding: 4px 0px !important;
        margin-bottom: 15px auto !important;
        margin-left: 6.75% !important;
        margin-top:-3% !important;
        font-weight: normal !important;
        border-radius: 4px !important;
    }
    .showcasediv {
        height: 85vh !important;
        margin-bottom: 0 !important;
    }

    .showcasingcolumndiv{
        margin-top:300px !important;
        margin-bottom:-700px !important;
    }
    .eventscontent-column {
        display: flex;
        align-items: center;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-top: 10px !important;
        padding-bottom: 0 !important;
    }
    /* Events  */
    .eventcontainer-class {
        height: 1000px !important;
    }
  
    .cardscontainer {
        height: 400px !important;
    }
    .eventscontent-column h2 {
        padding-top: 2em !important;
        display: flex !important;
        align-items: center;
        font-weight: 500 !important;
        font-size: 3.2vw !important;
        justify-content: center;
        width: 100%;
        padding-bottom: 5px !important;
    }
    .eventscontent-paragraph {
        width: 100%;
        font-size: 24px !important;
        padding-top: 6px !important;
        padding-bottom: 15px !important;
    }
    .events-card-home {
        margin-top: 15px !important;
        width: 100% !important;
        height: 500px !important;
    }
    .events-card-home .event-image-class {
        height: 40% !important;
        width: 100% !important;
    }
    .card-date {
        align-items: center !important;
        font-size: 2vw !important;
    }
    .events-card-home .float-right {
        padding-bottom: 5px !important;
        align-items: center !important;
        font-size: 2vw !important;
    }
    .events-card-home .card-title {
        line-height: 5%;
        /* margin-top:20px !important; */
        padding-top: 20px !important;
        padding-bottom: 10px !important;
        font-size: 2.25vw !important;
        width: 100%;
    }
    .events-card-home .card-text {
        text-align: justify !important;
        font-size: 1.85vw !important;
    }
    .ourteamheading {
        font-size: 5vw !important;
    }
    .email {
        font-size: 1.45vw !important;
        text-align: center;
        display: none !important;
    }
    .team-card {
        margin-bottom: 10vh !important;
    }
    .our-team .pic {
        height: auto !important;
    }
    .homemainheading {
        top: 44% !important;
        font-size: 3.5vw;
        margin: 0 auto !important;
        white-space: nowrap;
    }
    .homemaintagline {
        top: 62% !important;
        margin: 0px 25px !important;
        margin-top: -60px !important;
        font-size: 2.5vw;
        text-align: center;
        width: 90%;
    }
    .homelearnmorebutton {
        top: 78% !important;
        margin-top: -20px;
        font-size: 1.8vw;
    }
    /* Css for home page event  */
    .Event {
        height: 100% !important;
        /* width: 120vw; */
    }
    .eventcontainer {
        margin-bottom: 0px !important;
    }
    .event-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50vw !important;
        text-align: center;
        margin: 0 auto !important;
        height: 50vh;
    }
    .event-center {
        width: 100%;
        margin-top: 10px;
        display: block !important;
    }
    .event-center a {
        width: 100%;
        text-align: center;
        display: flex;
        margin-right: 0 !important;
        justify-content: center;
    }
    .event-center a input {
        text-align: center;
        display: flex;
        margin-top: 25px;
        margin-right: 0 !important;
        justify-content: center;
        border-radius: 12px;
    }
    .event-image {
        width: 100%;
        margin-bottom: 25px !important;
    }
    .eventheading {
        font-size: 40px;
        text-align: center;
        display: flex;
        width: 100%;
        justify-content: center;
    }
   
    .event-center h2 {
        text-align: center;
        margin: 10px 0px !important;
        width: 100%;
        white-space: nowrap;
        font-size: 3vw;
    }
    .event-center h3 {
        color: #1f2d3d;
        text-align: center;
        margin: 10px 0px !important;
        width: 100%;
        font-size: 2.2vw;
    }
    .event-center p {
        margin: 0 auto !important;
        text-align: center;
        font-size: 2.4vw;
        width: 80%;
    }
    .teams-special-margin {
        margin-top: 44em !important;
    }
}

@media screen and (min-width: 900px) and (max-width: 1024px) {
    .showcasediv {
        height: 100vh !important;
        margin-bottom: 0 !important;
    }
    .eventscontent-column h2 {
        font-size: 2em !important;
    }
    .eventscontent-column .eventscontent-paragraph {
        font-size: 24px !important;
    }
    .card-date {
        align-items: center !important;
        font-size: 1.4vw !important;
    }
    .events-card-home {
        height: 500px !important;
    }
    .events-card-home .float-right {
        padding-bottom: 5px !important;
        align-items: center !important;
        font-size: 1.5vw !important;
    }
    .events-card-home .card-title {
        line-height: 5%;
        /* margin-top:20px !important; */
        padding-top: 20px !important;
        padding-bottom: 10px !important;
        font-size: 2vw !important;
        width: 100%;
    }
    .events-card-home .card-text {
        text-align: justify !important;
        font-size: 1.55vw !important;
    }
    .teams-special-margin {
        margin-top: 49.5em !important;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1201px) {
    /* New css for teams and event */
    .home {
        height: 100vh !important;
    }
    /* Css for home page event  */
    .homecontainer {
        /* min-width: 130vw ; */
        margin-top: -350px !important;
        height: auto !important;
    }
    .navbar-brand {
        margin-left: -50%;
    }
    .textcasediv h2 {
        font-weight: bolder !important;
        position: absolute;
        top: 77.5vh !important;
        left: 6vw !important;
        font-size: 2.5em !important;
    }
    .taglinediv {
        position: absolute;
        top: 80.5vh !important;
        left: 5.5vw !important;
        height: 20.5em !important;
        padding: 0 !important;
    }
    .taglinediv h5 {
        margin-left: 0 !important;
        font-weight: normal !important;
        font-size: 22.5px !important;
    }
    .taglinediv a {
        height: 9.5vh !important;
        font-size: 2vw !important;
        padding: 8px 0px !important;
        margin-bottom: 15px auto !important;
        margin-left: 0 !important;
        font-weight: 400 !important;
        border-radius:4px !important;
    }
    .showcasediv {
        height: 100vh !important;
        margin-bottom: 0 !important;
    }
    .showcasingcolumndiv{
        margin-top:300px !important;
    }
    .eventscontent-column {
        display: flex;
        align-items: center;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-top: 10px !important;
        padding-bottom: 0 !important;
    }
    /* Events  */
    .eventcontainer-class {
        height: 1000px !important;
    }
  
    .cardscontainer {
        height: 400px !important;
    }
    .eventscontent-column h2 {
        padding-top: 6em !important;
        display: flex !important;
        align-items: center;
        font-weight: 500 !important;
        font-size: 2em !important;
        justify-content: center;
        width: 100%;
        padding-bottom: 5px !important;
    }
    .eventscontent-paragraph {
        width: 100%;
        font-size: 24px !important;
        padding-top: 6px !important;
        padding-bottom: 15px !important;
    }
    .events-card-home {
        margin-top: 55px !important;
        width: 80% !important;
        height: 500px !important;
    }
    .events-card-home .event-image-class {
        height: 40% !important;
        width: 100% !important;
    }
    .card-date {
        align-items: center !important;
        font-size: 1.5vw !important;
    }
    .events-card-home .float-right {
        padding-bottom: 5px !important;
        align-items: center !important;
        font-size: 1.5vw !important;
    }
    .events-card-home .card-title {
        line-height: 5%;
        /* margin-top:20px !important; */
        padding-top: 20px !important;
        padding-bottom: 10px !important;
        font-size: 2vw !important;
        width: 100%;
    }
    .events-card-home .card-text {
        text-align: justify !important;
        font-size: 1.45vw !important;
        width: 99% !important;
    }
    .ourteamheading {
        font-size: 5vw !important;
    }
    .email {
        font-size: 1.45vw !important;
        text-align: center;
        display: none !important;
    }
    .team-card {
        margin-bottom: 10vh !important;
    }
    .our-team .pic {
        height: auto !important;
    }
    /* Css for home page event  */
    .homecontainer {
        /* min-width: 130vw ; */
        position: relative;
        max-height: 100vh;
    }
    .homediv {
        margin-top: 470px;
    }
    .homemainheading {
        top: 44% !important;
        font-size: 3vw;
        margin: 0 auto !important;
        white-space: nowrap;
    }
    .homemaintagline {
        top: 62% !important;
        margin: 0px 25px !important;
        margin-top: -60px !important;
        font-size: 2vw;
        text-align: center;
        width: 90%;
    }
    .homelearnmorebutton {
        top: 78% !important;
        margin-top: -20px;
        font-size: 1.5vw;
    }
    /* Css for home page event  */
    .Event {
        height: 100% !important;
        /* width: 120vw; */
    }
    .eventcontainer {
        margin-bottom: 0px !important;
    }
    .event-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50vw !important;
        text-align: center;
        margin: 0 auto !important;
        height: 50vh;
    }
    .event-center {
        width: 100%;
        margin-top: 10px;
        display: block !important;
    }
    .event-center a {
        width: 100%;
        text-align: center;
        display: flex;
        margin-right: 0 !important;
        justify-content: center;
    }
    .event-center a input {
        text-align: center;
        display: flex;
        margin-top: 25px;
        margin-right: 0 !important;
        justify-content: center;
        border-radius: 12px;
    }
    .event-image {
        width: 100%;
        margin-bottom: 25px !important;
    }
    .eventheading {
        font-size: 40px;
        text-align: center;
        display: flex;
        width: 100%;
        justify-content: center;
    }
   
    .event-center h2 {
        text-align: center;
        margin: 10px 0px !important;
        width: 100%;
        white-space: nowrap;
        font-size: 2.5vw;
    }
    .event-center h3 {
        color: #1f2d3d;
        text-align: center;
        margin: 10px 0px !important;
        width: 100%;
        font-size: 2vw;
    }
    .event-center p {
        margin: 0 auto !important;
        text-align: center;
        font-size: 2.2vw;
        width: 80%;
    }
    .teams-special-margin {
        margin-top: 54em !important;
    }
}

@media screen and (min-width: 1202px) and (max-width: 1800px) {
    /* Css for new section team and event  */
    .home {
        height: 1000vh !important;
    }
    /* Css for home page event  */
    .navbar-brand {
        margin-left: -50%;
    }
   
    .textcasediv h2 {
        font-weight: bolder;
        position: absolute;
        color: #ec3750 !important;
        top: 90vh !important;
        left: 3vw !important;
        font-size: 3.5em !important;
     
    }
    .taglinediv {
        position: absolute;
        top: 95vh !important;
        left: 3vw !important;
        height: 20.5em !important;
        padding: 0 !important;
     
    }
    .taglinediv h5 {
        text-align: left !important;
        font-size:25px !important;
        margin-left: 0 !important;
    }
    .taglinediv a {
        font-weight: normal;
        height: 9.5vh !important;
        font-size: 1em !important;
        padding: 8px 0px !important;

        margin-left:0 !important;
        border-radius:4px !important;
    }
    .showcasediv {
        height: 140vh !important;
        margin-bottom: -150px !important;
    }

    .showcasingcolumndiv{
        margin-top:320px !important;
    }

    .eventscontent-column {
        display: flex;
        align-items: center;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-top: 10px !important;
        padding-bottom: 0 !important;
    }
    /* Events  */
    .eventcontainer-class {
        height: 1000px !important;
    }
  
    .cardscontainer {
        height: 400px !important;
    }
    .eventscontent-column h2 {
        padding-top: 6em !important;
        display: flex !important;
        align-items: center;
        font-weight: 500 !important;
        font-size: 2em !important;
        justify-content: center;
        width: 100%;
        padding-bottom: 5px !important;
    }
    .eventscontent-paragraph {
        width: 100%;
        font-size: 24px !important;
        padding-top: 6px !important;
        padding-bottom: 15px !important;
    }
    .events-card-home {
        margin-top: 55px !important;
        width: 20rem !important;
        height: 500px !important;
    }
    .events-card-home .event-image-class {
        height: 40% !important;
        width: 100% !important;
    }
    .card-date {
        align-items: center !important;
        font-size: 1vw !important;
    }
    .events-card-home .float-right {
        padding-bottom: 5px !important;
        align-items: center !important;
        font-size: 1vw !important;
    }
    .events-card-home .card-title {
        line-height: 3%;
        /* margin-top:20px !important; */
        padding-top: 20px !important;
        padding-bottom: 10px !important;
        font-size: 1.1vw !important;
        width: 100%;
    }
    .events-card-home .card-text {
        text-align: justify !important;
        font-size: 1vw !important;
        width: 99% !important;
    }
    .ourteamheading {
        font-size: 5vw !important;
    }
    .email {
        font-size: 0.95vw !important;
        text-align: left;
        display: none !important;
    }
    .team-card {
        margin-bottom: 10vh !important;
    }
    .our-team .pic {
        height: auto !important;
    }
    /* END OF NEW CSS  */
    /* Css for home page event  */
    .homecontainer {
        /* min-width: 130vw ; */
        position: relative;
        max-height: 120vh;
        /* margin-bottom: 10vh;  */
    }
   
    /* Css for home page event  */
    
    .eventcontainer {
        margin-bottom: 0px !important;
    }
    
    
    .event-center {
        margin: 0 auto !important;
    }
    .event-center a {
        width: 100%;
        text-align: center;
        display: flex;
        margin-right: 0 !important;
        justify-content: center;
    }
    .event-center a input {
        text-align: center;
        display: flex;
        margin-top: 25px;
        margin-right: 0 !important;
        justify-content: center;
        border-radius: 12px;
    }
    .event-image {
        width: 100%;
        margin-bottom: 25px !important;
    }
    .eventheading {
        font-size: 40px;
        text-align: center;
        margin-top: 200px;
        display: flex;
        width: 100%;
        justify-content: center;
    }
  
    .event-center h2 {
        text-align: center;
        margin: 10px 0px !important;
        width: 100%;
        white-space: nowrap;
        font-size: 2.25vw;
    }
    .event-center h3 {
        color: #1f2d3d;
        text-align: center;
        margin: 10px 0px !important;
        width: 100%;
        font-size: 1.5vw;
    }
    .event-center p {
        margin: 0 auto !important;
        text-align: justify;
        font-size: 1.8vw;
        width: 80%;
    }
    /* .teams-special-margin {
        margin-top: 6em !important;
    } */
}

@media screen and (min-width: 1202px) and (max-width: 1800px) {
    @media screen and (max-height: 813px) {
        .teams-special-margin {
            margin-top: 6em !important;
        }
    }
   
}

@media screen and (min-width: 1450px) and (max-width: 1650px) {
    .home {
        height: 1000vh !important;
    }
    /* Css for home page event  */
    .navbar-brand {
        margin-left: -50%;
    }
    .textcasediv h2 {
        
        position: absolute;
        top: 80vh !important;
        color: #ec3750 !important;
        left: 4vw !important;
        font-size: 64px !important;
        font-weight: bolder;
    }
    .taglinediv {
        position: absolute;
        top: 90vh !important;
        left: 2vw !important;
        height: 20.5em !important;
        padding: 0 !important;
    }
    .taglinediv h5 {
        text-align: left !important;
        font-family: 'Poppins', sans-serif !important; 
        font-weight: bold;
        font-size: 30px !important;
        margin-left:5.5% !important;
    }
    .taglinediv a {
        font-weight: 400 !important;
        margin-left: 5% !important;
        height: 9.5vh !important;
        font-size: 2vw !important;
        padding: 8px 0px !important;
        margin-bottom: 15px auto !important;
        border-radius:4px !important;
    }
    .showcasediv {
        border-bottom:2px solid black !important;
        background-color: #ffff;
        height: 120vh !important;
        margin-bottom: 0 !important;
    }

    .showcasingcolumndiv{
        margin-top:320px !important;
    }
    .eventscontent-column {
        display: flex;
        align-items: center;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-top: 10px !important;
        padding-bottom: 0 !important;
    }
    /* Events  */
    .eventcontainer-class {
        height: 1000px !important;
    }
  
    .cardscontainer {
        height: 400px !important;
    }
    .eventscontent-column h2 {
        padding-top: 6em !important;
        display: flex !important;
        align-items: center;
        font-weight: 500 !important;
        font-size: 2em !important;
        justify-content: center;
        width: 100%;
        padding-bottom: 5px !important;
    }
    .eventscontent-paragraph {
        width: 100%;
        font-size: 24px !important;
        padding-top: 6px !important;
        padding-bottom: 15px !important;
    }
    .events-card-home {
        margin-top: 55px !important;
        width: 24rem !important;
        height: 500px !important;
    }
    .events-card-home .event-image-class {
        height: 40% !important;
        width: 100% !important;
    }
    .card-date {
        align-items: center !important;
        font-size: 1vw !important;
    }
    .events-card-home .float-right {
        padding-bottom: 5px !important;
        align-items: center !important;
        font-size: 1vw !important;
    }
    .events-card-home .card-title {
        line-height: 5%;
        /* margin-top:20px !important; */
        padding-top: 20px !important;
        padding-bottom: 10px !important;
        font-size: 1.2vw !important;
        width: 100%;
    }
    .events-card-home .card-text {
        text-align: justify !important;
        font-size: 1vw !important;
        width: 99% !important;
    }
    .ourteamheading {
        font-size: 5vw !important;
    }
    .email {
        font-size: 0.95vw !important;
        text-align: left;
        display: none !important;
    }
    .team-card {
        margin-bottom: 10vh !important;
    }
    .our-team .pic {
        height: auto !important;
    }
    /* END OF NEW CSS  */
}

@media screen and (min-width: 1651px) and (max-width: 1851px) {
    .home {
        height: 1000vh !important;
    }
    /* Css for home page event  */
    .navbar-brand {
        margin-left: -50%;
    }
    .textcasediv h2 {
        position: absolute;
        top: 80vh !important;
        color: #ec3750 !important;
        left: 6vw !important;
        font-size: 64px !important;
    }
    .taglinediv {
        position: absolute;
        top: 105vh !important;
        left: 5.5vw !important;
        height: 28.5em !important;
        padding: 0 !important;
    }
    .taglinediv h5 {
        font-size: 40px !important;
    }
    .taglinediv a {
        height: 9.5vh !important;
        font-size: 2vw !important;
        padding: 8px 0px !important;
        margin-bottom: 15px auto !important;
    }
    .showcasediv {
        height: 140vh !important;
        margin-bottom: 0 !important;
    }
    .eventscontent-column {
        display: flex;
        align-items: center;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-top: 10px !important;
        padding-bottom: 0 !important;
    }
    /* Events  */
    .eventcontainer-class {
        height: 1000px !important;
    }
 
    .cardscontainer {
        height: 400px !important;
    }
    .eventscontent-column h2 {
        padding-top: 6em !important;
        display: flex !important;
        align-items: center;
        font-weight: 500 !important;
        font-size: 2em !important;
        justify-content: center;
        width: 100%;
        padding-bottom: 5px !important;
    }
    .eventscontent-paragraph {
        width: 100%;
        font-size: 24px !important;
        padding-top: 6px !important;
        padding-bottom: 15px !important;
    }
    .events-card-home {
        margin-top: 55px !important;
        width: 28.5rem !important;
        height: 500px !important;
    }
    .events-card-home .event-image-class {
        height: 40% !important;
        width: 100% !important;
    }
    .card-date {
        align-items: center !important;
        font-size: 1vw !important;
    }
    .events-card-home .float-right {
        padding-bottom: 5px !important;
        align-items: center !important;
        font-size: 1vw !important;
    }
    .events-card-home .card-title {
        line-height: 5%;
        /* margin-top:20px !important; */
        padding-top: 20px !important;
        padding-bottom: 10px !important;
        font-size: 1.35vw !important;
        width: 100%;
    }
    .events-card-home .card-text {
        text-align: justify !important;
        font-size: 1vw !important;
        width: 99% !important;
    }
    .ourteamheading {
        font-size: 5vw !important;
    }
    .email {
        font-size: 0.95vw !important;
        text-align: left;
        display: none !important;
    }
    .team-card {
        margin-bottom: 10vh !important;
    }
    .our-team .pic {
        height: auto !important;
    }
    /* END OF NEW CSS  */
}

@media screen and (min-width: 1801px) and (max-width: 2200px) {
    /* Css for home page event  */
    .homecontainer {
       
        position: relative;
        max-height: 120vh;
       
    }
    
    /* Css for home page event  */
   
    .eventcontainer {
        margin-bottom: 0px !important;
    }
  
    .event-center {
        margin: 0 auto !important;
    }
    .event-center a {
        width: 100%;
        text-align: center;
        display: flex;
        margin-right: 0 !important;
        justify-content: center;
    }
    .event-center a input {
        text-align: center;
        display: flex;
        margin-top: 25px;
        margin-right: 0 !important;
        justify-content: center;
        border-radius: 12px;
    }
    .event-image {
        width: 100%;
        margin-bottom: 25px !important;
    }
    .eventheading {
        font-size: 40px;
        text-align: center;
        margin-top: 200px;
        display: flex;
        width: 100%;
        justify-content: center;
    }
  
    .event-center h2 {
        text-align: center;
        margin: 10px 0px !important;
        width: 100%;
        white-space: nowrap;
        font-size: 2.25vw;
    }
    .event-center h3 {
        color: #1f2d3d;
        text-align: center;
        margin: 10px 0px !important;
        width: 100%;
        font-size: 1.5vw;
    }
    .event-center p {
        margin: 0 auto !important;
        text-align: justify;
        font-size: 1.8vw;
        width: 80%;
    }
}

@media screen and (min-width: 1852px) and (max-width: 2200px) {
    .home {
        height: 1000vh !important;
    }
    /* Css for home page event  */
    .navbar-brand {
        margin-left: -50%;
    }
    .textcasediv h2 {
        position: absolute;
        top: 80vh !important;
        color: #ec3750 !important;
        left: 6vw !important;
        font-size: 64px !important;
    }
    .taglinediv {
        position: absolute;
        top: 105vh !important;
        left: 5.5vw !important;
        height: 29.5em !important;
        padding: 0 !important;
    }
    .taglinediv h5 {
        font-size: 40px !important;
    }
    .taglinediv a {
        height: 11.5vh !important;
        font-size: 2vw !important;
        padding: 8px 0px !important;
        margin-bottom: 15px auto !important;
    }
    .showcasediv {
        height: 140vh !important;
        margin-bottom: 0 !important;
    }
    .eventscontent-column {
        display: flex;
        align-items: center;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-top: 10px !important;
        padding-bottom: 0 !important;
    }
    /* Events  */
    .eventcontainer-class {
        height: 1000px !important;
    }
  
    .cardscontainer {
        height: 400px !important;
    }
    .eventscontent-column h2 {
        padding-top: 6em !important;
        display: flex !important;
        align-items: center;
        font-weight: 500 !important;
        font-size: 2em !important;
        justify-content: center;
        width: 100%;
        padding-bottom: 5px !important;
    }
    .eventscontent-paragraph {
        width: 100%;
        font-size: 24px !important;
        padding-top: 6px !important;
        padding-bottom: 15px !important;
    }
    .events-card-home {
        margin-top: 55px !important;
        width: 30.5rem !important;
        height: 500px !important;
    }
    .events-card-home .event-image-class {
        height: 40% !important;
        width: 100% !important;
    }
    .card-date {
        align-items: center !important;
        font-size: 1vw !important;
    }
    .events-card-home .float-right {
        padding-bottom: 5px !important;
        align-items: center !important;
        font-size: 1vw !important;
    }
    .events-card-home .card-title {
        line-height: 5%;
        /* margin-top:20px !important; */
        padding-top: 20px !important;
        padding-bottom: 10px !important;
        font-size: 1.25vw !important;
        width: 100%;
    }
    .events-card-home .card-text {
        text-align: justify !important;
        font-size: 0.95vw !important;
        width: 99% !important;
    }
    .ourteamheading {
        font-size: 5vw !important;
    }
    .email {
        font-size: 0.95vw !important;
        text-align: left;
        display: none !important;
    }
    .team-card {
        margin-bottom: 10vh !important;
    }
    .our-team .pic {
        height: auto !important;
    }
    /* END OF NEW CSS  */
}

/* Css for home page event  */

/* COntact us  */

/* COntact us  */

#contact-us {
    margin-bottom: 45px;
    box-shadow: grey;
    margin-top: 13vw;

}

/* Git commit on 04 oct Changed contact us section id of teams to teams section  */

#teams-section {
    margin-bottom: 55px;
    box-shadow: grey;
    margin-top: 13vw;

}

.contact-us-leftmost-container {
    text-align: center;
    border-radius: 10px 0px 0px 10px;
}

.contact-us-rightmost-container {
    border-radius: 0px 10px 10px 0px;
}

.contact-us-image {
    height: 100%;
    width: 100%;
    border-radius: 20px;
    display: block;
}



.contact-us-name-div {
    margin-bottom: 15px;
    text-align: left;
    font-size: 20px;
}

.contact-us-name-div input {
    border-radius: 12px;
}

.contact-us-name-div input:hover {
    box-shadow: 0px 0px 1px 1px pink;
}

.contact-us-name-div input:focus {
    box-shadow: 0px 0px 1px 1px pink;
}

.contact-us-email-div {
    font-size: 20px;
    text-align: left;
    margin-bottom: 15px;
}

.contact-us-email-div input {
    border-radius: 12px;
}

.contact-us-email-div input:hover {
    box-shadow: 0px 0px 1px 1px pink;
}

.contact-us-email-div input:focus {
    box-shadow: 0px 0px 1px 1px pink;
}

.contact-us-message-div {
    font-size: 20px;
    margin-bottom: 15px;
}

.contact-us-message-div textarea {
    border-radius: 12px;
}

.contact-us-message-div textarea:hover {
    box-shadow: 0px 0px 1px 1px pink;
}

.contact-us-message-div textarea:focus {
    box-shadow: 0px 0px 1px 1px pink;
}

.submit-btn-div {
    text-align: center !important;
    display: flex !important;
    align-items: center;
}

.submit-btn {
    color: #fff !important;
    background-color: #ec3750 !important;
    border-radius: 12px;
    /* padding:25px; */
    text-align: center !important;
    display: flex;
    align-items: center;
    margin: 0 auto;
}

.submit-btn:hover {
    cursor: pointer;
    transform: scale(1.15);
    transition: 0.25s ease-out;
    color: #fff;
    opacity: 1;
}

#contact-us .row {
    margin-top: 75px;
}

#teams-section .specialdiv{
    width: 24rem;
    height: 2.5px;
    background: #b9b3b3;
    margin: 1rem auto;
}

.ourteamheading .specialdiv {
    width: 24rem;
    height: 2.5px;
    background: #b9b3b3;
    margin: 1rem auto;
}

#contact-us .specialdiv {
    width: 24rem;
    height: 2.5px;
    background: #b9b3b3;
    margin: 1rem auto;
}

#aboutus .specialdiv {
    width: 24rem;
    height: 2.5px;
    background: #b9b3b3;
    margin: 1rem auto;
}

/* @media screen and (max-width:1090px) {
    #contact-us{
        margin-left: 30px !important;
    }
    
}

@media screen and (max-width:881px) {
    #contact-us{
        margin-left: 0px !important;
    }
    
}
@media screen and (max-width:820px) {
    #contact-us{
        margin-left: -20px !important;
    }
    
}
@media screen and (max-width:760px) {
    #contact-us{
        margin-left: 20px !important;
    }
.contact-us-left-container{
    width:60% !important;
}
.contact-us-right-container{
    width:40% !important;
}
    
    
} */
/* @media screen and (max-width:748px) {
    #contact-us{
        margin-left: 0px !important;
    }

    
    
}
@media screen and (max-width:706px) {
    .contact-us-left-container{
        width:60% !important;
    }
    .contact-us-right-container{
        width:10% !important;
    }
    #contact-us{
        margin-left: -100px !important;
    }
   

    
    
} */
/* @media screen and (max-width:666px) {
    .contact-us-left-container{
        width:80% !important;
        margin-left: 270px !important;
        

    }
    .contact-us-right-container{
        width:60% !important;
        margin-left: auto;
    }} */

/* @media screen and (max-width:586px) {
        .contact-us-left-container{
            width:80% !important;
            margin-left: 70px !important;
           
        }
        .contact-us-right-container{
            width:100% !important;
           
            margin-right: -230px !important;
        }}

 @media screen and (max-width:513px) {
            .contact-us-left-container{
                margin-left: 70px !important;
               
            }
            .contact-us-right-container{
                margin-right: -180px !important;
            }} */

 /* @media screen and (max-width:461px) {
    .contact-us-left-container{
        margin-left: 120px !important;
       
    }
    
            .contact-us-right-container{
                   width:60% !important;
                   margin-right: 20px !important;
                }}     
   
@media screen and (max-width:401px) {
    .contact-us-left-container{
       width: 90% !important;
       margin-left:190px !important
       
    }
                    
    .contact-us-right-container{
        margin-right: 60px !important;
    }}     
                   
                
 */
    
@media screen  and (max-width:995px){
    .contactusheading{
        margin-left: 0;
        margin-right:0 ;
    }
    
    #teams-section .specialdiv{
        width:60%;
    }

    #aboutus .specialdiv {
        width:51%;
    }

    #contact-us .specialdiv{
        width:55%;
        margin : 5% auto;
    }
    
}
@media screen  and (max-width:905px){
    .contactusheading{
        margin-left: 0;
        margin-right:0 ;
    }
    #contact-us .specialdiv{
        margin : 0 auto;
    }
    
}
@media screen  and (max-width:768px){
    .contactusheading{
        margin-left: 0;
        margin-right:0 ;
    }
}
@media screen  and (max-width:760px){
    .contactusheading{
        margin-left: 0;
        margin-right:0 ;
    }
    #contact-us .specialdiv{
        margin : 5% auto;
    }
}
@media screen  and (max-width:707px){
    .contactusheading{
        margin-left: 0;
        margin-right:0 ;
    }
    #contact-us .specialdiv{
       margin : 5% auto;
    }
}
@media screen  and (max-width:667px){

    

    .aboutusheading{
        margin-top:20px !important
    }
}
@media screen  and (max-width:650px){
    .contactusheading{
        margin-left: 0;
        margin-right:0 ;
    }
   
}
@media screen  and (max-width:560px){
    .contactusheading{
        margin-left: 0;
        margin-right:0 ;
    }
   
}
@media screen  and (max-width:480px){
    .contactusheading{
     text-align: center;
     margin-left: 0;
     margin-right:0 ;
    }
   
}

.facebook {
    background-image: url(./eventimages/socialmedia/fb.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
}



.youtube {
    background-image: url(./eventimages/socialmedia/youtube.png);
    background-repeat: repeat-y !important;
    background-size: 100%;
    color: #fff !important;
    background-color: #fff !important;
}

.linkedin {
    background-image: url(./eventimages/socialmedia/link.jpg);
    background-repeat: no-repeat;
    background-size: 120%;
}

.instagram {
    background-image: url(./eventimages/socialmedia/insta.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
}

/* Previouis css */

.homecontainer {
    background: white;
    margin-top: -340px;
    width: 100%;
    height: 700px
}
/* Did some changes 26 sep */
.showcasingcolumndiv{
    background-image: url('/src/eventimages/hom.gif');
    background-repeat: no-repeat;
    background-size: 100%;

    float: right;
    margin-left: 50px ;
    margin-top: 300px ;
    margin-bottom: -300px;
    height: 700px;
}

.showcasediv {

    background-repeat: no-repeat;
    background-size: 90%;
    width: 100%;
    float: right;
    margin-left: 50px !important;
    margin-top: 170px !important;
    /* margin-bottom: -70px;
    height: 700px; */
   
}

@media screen  and (max-width:1201px){
    .showcasediv {
    margin-top: 280px !important;
    
}
}

@media screen  and (max-width:1100px){
    .showcasediv {
    margin-top: 280px !important;
    
}
}
@media screen  and (max-width:900px){
    .showcasediv {
    margin-top: 340px !important;
    
}
}
@media screen  and (max-width:851px){
    .showcasediv {
    margin-top: 385px !important;
    
}
}
@media screen  and (max-width:767px){
    .showcasediv {
    margin-top: 360px !important;
    
}
}
@media screen  and (max-width:700px){
    .showcasediv {
    margin-top: 300px !important;
    
}
}
@media screen  and (max-width:651px){
    .showcasediv {
    margin-top: 370px !important;
    
}
}
@media screen  and (max-width:550px){
    .showcasediv {
    margin-top: 350px !important;
    
}
}



.homecontainer h2 {
    color: #ec3750 !important;
    position: absolute;
    top: 48%;
    font-size: 64px;
    left: 8vw;
}

.homecontainer h3 {
    color: black;
    position: absolute;
    top: 70%;
    font-size: 36px;
    left: 12vw;
}

.homecontainer h5 {
    font-weight: 500;
    color: #0D1119;
    text-align: center;
    font-size: 1.9vw;
}

.taglinediv {
    display: flex;
    position: absolute;
    top: 65%;
    left: 4.5vw;
    /* background: rgba(37, 37, 37, 0.5); */
    width: 40%;
    padding-bottom: 30px;
    height: 40vh;
    flex-wrap: wrap;
}

.taglinediv h5 {
    text-align: left !important;
    padding-top: 20px;
    margin: 0px auto;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.taglinediv a {
    font-weight: bolder;
    border: none;
    -webkit-appearance: none;
    appearance: none;
    text-decoration: none;
    border-radius: 12px;
    appearance: none;
    height: 25%;
    color: #fff;
    font-size: 20px;
    background-color: #EC3B4F;
    margin-left: 10px ;
    margin: 0 auto;
    text-align: center;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 30px;
    width: 35%;
}
.taglinediv a:hover{
    background-color: #F45947;
    color:white;
}
.taglinediv a:active{
   background-color: white;
    transform: translateX(5px)
}
/* Media queries to handle the background gif */

/* navbar-class */

/* end of navbar-class */

/* Home Main Area  */

/* Home Main Area END */

/*event*/



.homecontainer {
    height: 1000px;
}

/* Small Mobile size ended  */

/* 481px — 768px */

/* Css for home page event  */

/* COntact us  */

/* COntact us  */

/* NEw stuff */

.eventrow {
    height: 85vh !important;
}

.eventscontent {
    padding: 0 25px;
    text-align: left !important;
}

.cardscontainer {
    margin-bottom: 100px;
}

.Event {
    height: auto !important;
}

.rec {
    margin-bottom: 100px;
}

@media screen and (max-width: 478px) {

    .rec {
        height: 500px;
    }
    .card-body {
        
        overflow: scroll;
    }
}

.card {
    border-radius: 12px !important;
    box-shadow: rgba(54, 53, 53, 0.35) 0px 5px 15px;
    width: 18rem;
    margin-bottom: 10px;
}

.cardcontent {
    display: flex;
    width: 100%;
}

.cardcontent .float-left {
    display: flex;
    justify-content: flex-start;
    float: left;
    width: 50%;
}

.cardcontent .float-right {
    display: flex;
    justify-content: flex-end;
    float: right;
    width: 100%;
}
.card-body h6{
    font-weight: bold;
}

.event-image-class {
    text-align: center !important;
    margin: auto !important;
    width: 100% !important;
    height: 40vh !important;
}

/* Event area css */

.eventscontent {
    display: flex;
    align-items: center;
}

.eventscontent h2 {
    padding-bottom: 10px;
    border-bottom: 2.5px solid #b9b3b3;
    text-align: center;
    margin: 0 auto;
    display: flex;
    align-content: center;
    font-weight: 700 !important;
    font-size: 1.75em ;
    line-height: 46px;
    color: #F45947;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.eventscontent-container {
    display: flex;
    justify-content: center;
    align-self: center;
    align-content: center;
}

.eventscontent-column {
    padding: 15vh 0px;
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    align-content: center;
}

.eventscontent-paragraph {
    font-weight: 500 !important;
    padding: 25px 0px;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    text-align: justify !important;
    color: #0D1119;
}

/*about us*/

.aboutusheading {
    font-size: 2em  !important;
    text-align: center;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 40px;
    font-weight: 700 !important;
    color: #F45947;
}
.contactusheading{
    font-size: 2em !important;
    text-align: center;
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 700 !important;
    margin-bottom: 20px !important;
    color: #F45947;
    

}

.img {
   
    margin-top: 0px !important;
}



@media screen and (min-width: 769px) and (max-width: 1024px) {
    .aboutusheading {
        font-size: 2em;
        font-weight: 700 !important;
    }
   
}

@media screen and (min-width: 1025px) and (max-width: 1201px) {
    .aboutusheading {
        font-size: 2em;
        font-weight: 700 !important;
    }
   
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .contactusheading{
        font-size: 1.25em !important;
        text-align: center;
        display: flex;
        width: 100%;
        justify-content: center;
        font-weight: 700 !important;

    }
    .aboutusheading {
        font-size: 1.25em !important;
        text-align: center;
        display: flex;
        width: 100%;
        justify-content: center;
        font-weight: 700 !important;
    }
   
}

@media screen and (min-width: 481px) and (max-width: 650px) {
    .aboutusheading {
        font-size: 1.75em !important;
        text-align: center;
        display: flex;
        width: 100%;
        justify-content: center;
        font-weight: 700 !important;
    }
   
}



.p {
    justify-content: center;
   margin-right: 10px;
    margin-left: 500px;
    margin-top: -320px !important;
    font-size: 1rem !important;
}

@media screen and (min-width: 942px) and (max-width: 1078px) {
    .p {
        margin-top: -355px !important;
    }
}

@media screen and (min-width: 869px) and (max-width: 942px) {
    .p {
        margin-top: -380px !important;
    }
    .img {
        margin-top: 0px !important;
    }
}

@media screen and (min-width: 816px) and (max-width: 869px) {
    .p {
        margin-top: -380px !important;
        margin-left: 450px;
    }
   
}

@media screen and (max-width: 816px) {
    .p {
        margin-top: 150px !important;
        margin-left: 10px !important;
    }
    .img {
        margin-top: -10px !important;
        height: 50% !important;
        width: 90% !important;
        margin-left: 40px !important;
        margin-bottom: -150px !important;
    }
}
/* final responsive addition by HURERA */

@media only screen and (min-width: 851px) and (max-width: 992px) {
    .textcasediv h2 {
        position: absolute;
        font-weight: bolder !important;
         top: 58vh !important; 
        margin-top: 100px !important;
        left: 6.4vw !important;
        font-size: 2em !important;
    }
    .taglinediv {
        position: absolute;
        top: 75vh !important; 
        left: 3.5vw !important;
        height: 17.5em !important;
        padding: 0 !important;
    }
    .taglinediv h5 {
        font-size: 1em !important;
        margin-left: 7.5% !important;
        text-align: left !important;
        margin-bottom:0 !important;
    }
    .taglinediv a {

        height: 7.5vh !important;
        font-size: 2vw !important;
        padding: 4px 0px !important;
        margin-bottom: 15px auto !important;
        margin-left: 6.5% !important;
        margin-top:-3% !important;
        font-weight: normal !important;
        border-radius: 4px !important;
    }
  
}

@media only screen and (min-width: 678px) and (max-width: 850px) {

    .textcasediv h2 {
        position: absolute;
        font-weight: bolder !important;
        top:75vh !important;
        color: #ec3750 !important;
        left: 7.5vw !important;
        font-size: 2em !important;
    }

    .taglinediv{
        top:75vh !important;
        left : 7.5vw !important;
    }

    .taglinediv h5 {
        margin-left: 0px !important;
        font-size:0.88em !important;
        font-weight: normal;
        text-align: left !important;
    }

    .taglinediv a{
        font-weight: normal !important;
        margin-left:  0 !important;
        font-size: 0.75em !important;
        border-radius: 4px !important;
        margin-top:-3% !important;
    }

    .showcasediv{
        top:15%;
      height: 75vh !important;
    }

    .showcasingcolumndiv{
        position: absolute;
        min-height: 100%;
        background-size: 95%;
        top:45% !important;
       margin-top:42% !important;

    }

    

 
}

@media only screen and (min-width: 651px) and (max-width: 677px) {
    .textcasediv h2 {
        position: absolute;
        font-weight: bolder;
        top: 75vh !important;
        color: #ec3750 !important;
        left: 6vw !important;
        font-size: 2em !important;
    }

  
    .taglinediv{
        top:75vh !important;
        left : 6vw !important;
    }

    .taglinediv h5 {
        margin-left: 0px !important;
        font-size:0.8em !important;
        font-weight: normal;
        text-align: left !important;
    }

    .taglinediv a{
        font-weight: normal !important;
        margin-left:  0 !important;
        font-size: 0.75em !important;
        border-radius: 4px !important;
        margin-top:-3% !important;
    }

    .showcasediv{
      height: 75vh !important;
    }

    .showcasingcolumndiv{
        top:23% !important;
       margin-top:45% !important;

    }
}

@media only screen and (min-width: 500px) and (max-width: 650px) {

    .textcasediv h2 {
        font-weight: bolder !important;
        position: absolute;
        top: 30% !important;
        color: #ec3750 !important;
        left: 6vw !important;
        font-size: 1.5em !important;
    }

    .taglinediv{
        top:30% !important;
        left : 6vw !important;
    }

    .taglinediv h5 {
        margin-left: 0px !important;
        font-size:0.7em !important;
        font-weight: normal;
        text-align: left !important;
    }

    .taglinediv a{
        font-weight: normal !important;
        margin-left:  0 !important;
        font-size: 0.65em !important;
        border-radius: 4px !important;
    }

    .showcasediv{
      height: 60vh !important;
      margin-bottom: 0px !important;
    }

    .showcasingcolumndiv{
        top:15% !important;
       margin-top:5px !important;

    }

    
}

@media only screen and (min-width: 440px) and (max-width: 499px) {

    .textcasediv{
        width:50% !important;
    }
  

    .textcasediv h2 {
        font-weight: bolder !important;
        position: absolute;
        top: 25.5% !important;
        color: #ec3750 !important;
        left: 6vw !important;
        font-size: 1.5em !important;
    }

    .taglinediv{
        top: 26.55% !important;
        left : 6vw !important;
    }

    .taglinediv h5 {
        font-size:0.7em !important;
        font-weight: normal;
        text-align: left !important;
    }

    .taglinediv a{
        font-weight: normal !important;
        margin-left:  0 !important;
        font-size: 0.65em !important;
        border-radius: 4px !important;
    }

    .showcasediv{
      height: 47vh !important;
    }

    

    .showcasingcolumndiv {
        background-size: 95% !important;
        /* margin-top : 250px !important; */
        height: 50%;
        margin:0 !important ;
     top:15% !important;
     width:50% !important;
     margin-bottom: 100px;
      
    
    }
}


