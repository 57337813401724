.eventheading {
    margin-top: -50px;
}

.Event {
    margin-top: 100px;
    margin-left: 15px;
    height: 600px;
    margin-right: 15px;
}

.event-image {
    margin-left: 30px;
}


.event-center{
    padding-bottom:10px !important;
}


@media screen and (min-width: 1390px) and (max-width: 1440px) {
    .Event {
        margin-top: 133px;
    }
}
@media screen and (min-width: 1441px) {
    .Event {
        margin-top: 157px;
    }
}


 @media screen and (min-width: 1280px) {
   
   
 }

/*@media screen and (min-width: 992px) {} */

@media screen and (min-width: 900px) {
    .event-center h2 {
        margin-left: 1px;
        text-align: center;
        margin-right: 40px;
    }
    .event-center h3 {
        margin-left: 40px;
    }
    .event-center p {
        margin-left: 10px;
        margin-right: 20px;
    }
    .event-image {
        margin-left: 60px;
        width: 370px;
        height: 370px;
        margin-top: 10px;
    }
}

@media screen and (min-width: 820px) and (max-width: 899px) {
    .event-center h2 {
        margin-left: 1px;
        margin-right: 70px;
    }
    .event-center h3 {
        margin-left: 5px;
    }
    .event-center p {
        margin-left: 1px;
        margin-right: 40px;
    }
    .event-image {
        margin: auto;
       
    }
}

@media screen and (min-width: 725px) and (max-width: 820px) {
    .event-center h2 {
        margin-left: 5px;
        margin-right: 40px;
    }
    .event-center h3 {
        margin-left: 5px;
    }
    .event-center p {
        margin-left: 1px;
        margin-right: 30px;
    }
    .event-center a input {
        margin-right: 40px;
    }
    .event-image {
        margin-left: 10px;
    }
}

@media screen and (min-width: 600px) and (max-width: 724px) {
    .event-center p {
        margin-left: 40px;
        margin-right: 30px;
    }
    .event-image {
        margin: auto;
    }
}

@media screen and (max-width: 599px) {
    .Event {
        height: 1000px;
    }
    .event-center p {
        margin-left: 40px;
        margin-right: 30px;
    }
    .event-center h2 {
        margin-left: 28px;
        margin-right: 30px;
    }
}


/* haris code */
/* @media screen and (min-width: 1490px) {
    .eventcontainer{
        margin-top: 140px !important;

    }

    .eventheading{
        margin-top:  10%;
    }



}

@media screen and (min-width: 1590px) {

  

    .eventcontainer{
        margin-top: 140px !important;

    }

    .eventheading{
        margin-top:  3vh;
    }

    

}

@media screen and (min-width: 1690px) {
    .eventcontainer{
        margin-top: 140px !important;

    }

    .eventheading{
        margin-top:  3vh;
    }

    

} */