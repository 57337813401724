.navbar{
  
  display: flex;

  padding:0px 50px;
  min-height:100px ;
  height: 100px;
  z-index: 99999;
  position: fixed !important;
  top:0;
  width: 100%;
  background: transparent ;
  margin-top:0px;
  margin-bottom: -155px !important;
 
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: .5rem;
  padding-bottom: .5rem;
  
}

.navbar-active{
  background-color:#252427 !important;
}

.navbar-active a{
  font-weight: 600;
  text-transform: capitalize !important;
  font-family: "Phantom Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  color:rgb(255, 255, 255) !important;
}

.nav-link {
  text-transform: uppercase !important;
}

.navbar ul {
  
  display: flex;
  justify-content: flex-end ;
  width: 100%;
}

.navbar-brand{
 
  width: auto;
 
  font-size: 40px !important;
  height:100px;
  /* padding-bottom:15px; */

}

.navbar a{
  font-weight: 600;
 
  text-transform: uppercase !important; 
  font-family: "Phantom Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  color:#f3f4f3  ;
  font-size: 18px !important;
  margin: 0 10px;
}



.fa-bars {
  color:#fff !important;
  z-index: 1000;
}

.fa-times{
  color:#fff !important;
}


.menu-icons{
  display:none;


}


@media screen and (max-width:1000px)
{
  .navbar{
/*  */
  }

  .nav-item{
    position:relative;
  }

  .navbar-nav{
      justify-content: center !important;
    height: 100vh;
    display: flex !important;
    flex-direction: column !important;
    width: 100%;
    position: absolute !important;
    top: 11.5vh !important;
    left: -100%;
    opacity: 1 !important;
    transition: all 0.5s ease !important;

  }


.navactive{
    background: #31303D ;
    left : 0 !important;
    opacity :1;
    transition :all 0.5s ease;
    z-index:100000;
  }

  .nav-link{
text-align: center;
padding:2rem;
width:100%;
display: table;

  }


  .nav-link:hover{
 color: #EC3B4F !important; 
    border-radius:0;

  }


  .navbar-logo{
    position:absolute;
    top:0;
    left:0;
    transform: translate(25%,50%);
  }

  .menu-icons{
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%,30%);
 
    font-size:1.8rem;
    cursor: pointer;
  }

}