.ourteamheading {
    font-size: 1.75em !important;
    text-align: center;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: -320px;
    font-weight: 700 !important;
    color:#EC3B4F
}

.ourteamheading .specialdiv {
    width: 24rem;
    height: 5px;
    background: #F45947;
    margin: 1rem auto;
}

.Teamfacebook {
    width: 20px;
    height: 20px;
    background-image: url(../eventimages/socialmedia/image-1.png);
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 0 20px 20px 20px;
}

.Teamlinkedin {
    width: 20px;
    height: 20px;
    background-image: url(../eventimages/socialmedia/image-3.png);
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 0 20px 20px 20px;
}

.Teaminsta {
    width: 20px;
    height: 20px;
    background-image: url(../eventimages/socialmedia/instagram.png);
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 0 20px 20px 20px;
}

.ist-image {
    margin-top: -40px;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .ourteamheading {
        font-size: 1.75em;
        font-weight: 700 !important;
     
    }
}

@media screen and (min-width: 1025px) and (max-width: 1201px) {
    .ourteamheading {
        font-size: 1.75em;
        font-weight: 700 !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .ourteamheading {
        margin-top: 8% !important;
        font-size: 1.25em !important;
        text-align: center;
        display: flex;
        width: 100%;
        justify-content: center;
        font-weight: 1000 !important;
    }
}

@media screen and (min-width: 481px) and (max-width: 650px) {
    .ourteamheading {
        font-size: 1.75em !important;
        text-align: center;
        display: flex;
        width: 100%;
        justify-content: center;
        font-weight: 700 !important;
       
    }
}

.highlighword {
   
    margin-left: 7px;
    font-size: 20px !important;
    font-weight: 500 !important;
}



@media screen and (min-width: 200px) and (max-width: 480px) {
    .highlighword1 {
        text-shadow: 1px 1px purple;
        margin-left: 7px;
        font-size: 16px !important;
        font-weight: 500 !important;
    }
    .highlighword2 {
        text-shadow: 1px 1px rgb(230, 97, 119);
        margin-left: 7px;
        font-size: 16px !important;
        font-weight: 500 !important;
    }
}



.our-team {
    text-align: center;
    transition: all 0.5s ease 0s;
    width: 222px;
    border-radius: 10px;
    margin-top: 30px !important;
}

.our-team:hover {
    box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}

.our-team .pic {
    overflow: hidden;
    position: relative;
    border-radius: 50%;
    width: 222px !important;
    height: 220px !important;
}

.our-team .pic:before, .our-team .pic:after {
    content: "";
    width: 200%;
    height: 80%;
    background: rgba(38, 37, 37, 0.8);
    position: absolute;
    top: -100%;
    left: -4%;
    transform: rotate(45deg);
    transition: all 0.5s ease 0s;
}

.our-team .pic:after {
    /* background: rgba(8,75,77,0.8);*/
    background: #FFBD48;
    top: auto;
    left: auto;
    bottom: -100%;
    right: -4%;
}

.our-team:hover .pic:before {
    top: 0;
}

.our-team:hover .pic:after {
    bottom: 0;
}

.our-team .pic img {
    width: 100%;
    height: 220px;
}

.our-team .social {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    position: absolute;
    bottom: 45%;
    left: 3px;
    opacity: 0;
    z-index: 2;
    transition: all 0.5s ease 0.3s;
}

.our-team:hover .social {
    opacity: 1;
}

.our-team .social li {
    display: inline-block;
}

.our-team .social li a {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    color: #fff;
    margin-right: 10px;
    position: relative;
    transition: all 0.3s ease 0s;
}

.our-team .social li a:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 0 20px 20px 20px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.3s ease 0s;
}

.our-team .social li a:hover:after {
    transform: rotate(180deg);
}

.our-team .team-content {
    padding: 20px;
}

.our-team .title {
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 2px;
    color: #F45947;
    text-transform: uppercase;
    margin-bottom: 7px;
}

.our-team .post {
    display: block;
    font-size: 17px;
    font-weight: 600;
    color: #0D1119;
    text-transform: capitalize;
}

.our-team .email {
    display: block;
    font-size: 12px;
    font-weight: 600;
    color: #1a1717;
    text-transform: capitalize;
}

.make-flex {
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 2000px) {
    .our-team {
        margin-bottom: 30px;
    }
    .make-up-for-sd {
        display: none;
    }
}
@media only screen and (min-width:951px) and (max-width: 1100px)   {
    .ourteamheading{
        margin-top: -320px !important;
    }
 }
 

@media only screen and (min-width:800px) and (max-width: 950px)   {
   .ourteamheading{
       margin-top: -320px !important;
   }
}


@media only screen and (min-width: 768px) and ( max-width:994px) {
    .our-team .pic {
        width: 200px;
    }
    .our-team {
        width: 240px;
    }
    .make-up-for-sd {
        display: none;
    }
}

@media only screen and ( min-width:576px) and ( max-width:767px) {
    .our-team .pic {
        width: 200px;
    }
    .our-team {
        width: 225px;
        margin: auto;
    }
    .our-team .title {
        font-size: 19px;
    }
    .above-president {
        display: none;
    }
    .make-up-for-sd {
        display: block;
    }
}

@media only screen and (max-width: 575px) {
    .our-team .pic {
        width: 200px;
    }
    .our-team {
        width: 225px;
        margin: auto;
    }
    .our-team .title {
        font-size: 19px;
    }
    .above-president {
        display: none;
    }
    .make-up-for-sd {
        display: block;
    }
    .ourteamheading{
        margin-top: -340px !important;
    }
}

@media only screen and (max-width: 530px) {
    .our-team .pic {
        width: 200px;
    }
    .our-team {
        width: 225px;
        margin: auto;
    }
    .our-team .title {
        font-size: 19px;
    }
    .above-president {
        display: none;
    }
    .make-up-for-sd {
        display: block;
    }
}

@media only screen and (max-width: 481px){
    .ourteamheading{
        margin-top: -160px !important;
    }
}


@media screen and (max-width:319px) {
    .ourteamheading{
        margin-top: 500px !important;
    }    
}

.teamButton {
    margin-top: 20px;
    background-color: #EC3B4F;
    color: #fff;
    padding: 20px 37px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 12px;
    border: none;
    -webkit-appearance: none;
    appearance: none;
    text-decoration: none;
    
   
}
.teamButton:hover{
    background-color: #F45947;
    color:white;
}
.teamButton:active{
    background-color: white;
    transform: translateX(5px)
}